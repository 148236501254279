<script>
import ToggleBtnGroup from "./ToggleBtnGroup.vue";
import {
  PAGE_FORMATS,
  PAGE_FORMATS_OBJ,
  STAFF_TYPES,
  STAFF_TYPES_OBJ
} from "@/utils/constants";
import { computed } from "vue";
import store from "@/store";

export default {
  name: "GridTableControls",
  props: {
    isGov: {
      type: Boolean,
      default: true
    }
  },
  components: {
    ToggleBtnGroup
  },
  computed: {},
  setup() {
    const pageFormats = PAGE_FORMATS_OBJ;
    const staffTypes = STAFF_TYPES_OBJ;
    const externalStaff = STAFF_TYPES.external;
    const multiPagesType = PAGE_FORMATS.multi;
    const staffType = computed(() => store.getters.staffType);
    const pageFormat = computed(() => store.getters.pageFormat);
    const handleStaffTypeChange = value => store.commit("setStaffType", value);
    const handlePageFormatChange = value =>
      store.commit("setPageFormat", value);
    return {
      pageFormats,
      staffTypes,
      multiPagesType,
      externalStaff,
      staffType,
      pageFormat,
      handleStaffTypeChange,
      handlePageFormatChange
    };
  }
};
</script>

<template>
  <v-row
    :justify="$vuetify.breakpoint.mdAndUp ? 'start' : 'space-between'"
    align="center"
    class="btn-row pl-3 pt-3 pb-3 pr-3"
  >
    <toggle-btn-group
      :class="{ 'mr-12': $vuetify.breakpoint.mdAndUp }"
      :ini-value="staffType"
      :radio-items="staffTypes"
      :small="$vuetify.breakpoint.mdAndUp"
      label="Staff Type:"
      @output="handleStaffTypeChange"
    />
    <toggle-btn-group
      :class="{ 'mr-12': $vuetify.breakpoint.mdAndUp }"
      :ini-value="pageFormat"
      :radio-items="pageFormats"
      :small="$vuetify.breakpoint.mdAndUp"
      label="Page Format:"
      @output="handlePageFormatChange"
    />
  </v-row>
</template>

<style lang="scss" scoped></style>
