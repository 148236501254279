<template>
  <fieldset class="msa-btn-group">
    <legend>{{ label }}</legend>
    <v-btn-toggle
      v-bind="$attrs"
      v-on="$listeners"
      :value="selectedValue"
      :active-class="activeClass"
      :borderless="borderless"
      :rounded="rounded"
      :shaped="shaped"
      dense
    >
      <v-btn
        ripple
        role="radio"
        outlined
        :aria-checked="selectedValue === item.value"
        :small="small"
        :class="{'btn-selected': selectedValue === item.value}"
        :value="item.value"
        v-for="item in radioItems"
        :key="item.value"
        @click="emitValue(item.value)"
        :x-small="$vuetify.breakpoint.mdAndDown"
      >
        {{ item.label }}
      </v-btn>
    </v-btn-toggle>
  </fieldset>
</template>

<script>

export default {
  props: {
    iniValue: {
      validator: function(value) {
        // Perform validation logic
        return typeof value === 'string' || typeof value === 'number';
      },
      default: ''
    },
    radioItems: {
      type: Array,
      required: true
    },
    activeClass: {
      type: String,
      default: 'activeButton'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    shaped: {
      type: Boolean,
      default: false
    },
    borderless: {
      type: Boolean,
      default: false
    },
    small: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: 'Group'
    }
  },
  data() {
    return {
      selectedValue: this.iniValue
    };
  },
  methods: {
    emitValue(btnValue) {
      this.selectedValue = btnValue;
      this.$emit('output', btnValue);
    }
  }
};
</script>

<style lang="scss" scoped>
.msa-btn-group {
  border: none;
  legend {
    margin-bottom: 0.25rem;
    font-size: 0.9rem;
    font-weight: 600;
  }
}
::v-deep {
  .v-btn
 {
    background-color: $ads-light-10;
    color: $ads-navy;
    &.btn-selected {
      font-weight: bold;
      border-width: 2px;
      background-color: $ads-primary-navy;
      color: $ads-light-10;
    }
}
  &.theme--dark {
    .v-btn:not(.v-btn--contained) {
      color: $ads-dark-80;
      &.btn-selected {
        color: $ads-dark;
        background-color: $ads-secondary-blue-dark;
      }
    }
  }
}
</style>
